import { useParams } from "react-router-dom";
import WallyptoConnection from "../@types/WallyptoConnection";
import { useEffect } from "react";

const Main = () => {
  const { token, network } = useParams();

  const connnectionListener = async (jsonString: string) => {
    const response = JSON.parse(jsonString);
    await fetch("https://game.groundi.app/live/users/wallets/prepare", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": "1M7eG2zS",
        Token: token!,
      },
      body: JSON.stringify({
        status: response.status,
        requestType: response.requestType,
        signedMsg: response.signedMsg,
        address: response.address,
        publicKey: response.publicKey,
      }),
    });
  };

  const connection = WallyptoConnection.Builder.setRequestType(
    WallyptoConnection.REQUEST_TYPE.SIGN_MESSAGE
  )
    .setNetworkName(
      network === "hedera"
        ? WallyptoConnection.NETWORK_NAME.HBAR.MAINNET.Mainnet.HBAR
        : WallyptoConnection.NETWORK_NAME.KLAY.MAINNET.Cypress.KLAY
    )
    .setMessage("그라운디")
    .setConnectionListener(connnectionListener)
    .build();

  useEffect(() => {
    if (!token) return;
    connection.submit();
  });
  return <div></div>;
};

export default Main;
