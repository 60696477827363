/* eslint-disable */
function a0_0x343b(_0x50f0bf, _0x14bb19) {
  var _0x2231e1 = a0_0x2231();
  return (
    (a0_0x343b = function (_0x343bf9, _0x49c178) {
      _0x343bf9 = _0x343bf9 - 0xd3;
      var _0x49cb19 = _0x2231e1[_0x343bf9];
      return _0x49cb19;
    }),
    a0_0x343b(_0x50f0bf, _0x14bb19)
  );
}
function a0_0x2231() {
  var _0x5e8fc1 = [
    "isArray",
    "88947PITgvC",
    "requestType",
    "3785327PiPOmV",
    "821517csRgin",
    "rawTransaction",
    "848UIoetT",
    "542qDMHOX",
    "networkName",
    "prototype",
    "4999050FmvqUv",
    "iterator",
    "push",
    "accessToken",
    "13740naTqDH",
    "toStringTag",
    "call",
    "amd",
    "defineProperty",
    "6RLvLNJ",
    "WallyptoConnection",
    "length",
    "22708246oDXKCL",
    "addressToSign",
    "writable",
    "shift",
    "55nKBarP",
    "4190chieWF",
  ];
  a0_0x2231 = function () {
    return _0x5e8fc1;
  };
  return a0_0x2231();
}
(function (_0x541129, _0x1cbe01) {
  var _0x322761 = a0_0x343b,
    _0x4bac9e = _0x541129();
  while (!![]) {
    try {
      var _0x22f0f3 =
        (-parseInt(_0x322761(0xd7)) / 0x1) * (parseInt(_0x322761(0xeb)) / 0x2) +
        -parseInt(_0x322761(0xd4)) / 0x3 +
        (parseInt(_0x322761(0xde)) / 0x4) * (-parseInt(_0x322761(0xea)) / 0x5) +
        (parseInt(_0x322761(0xe3)) / 0x6) * (parseInt(_0x322761(0xd3)) / 0x7) +
        (-parseInt(_0x322761(0xd6)) / 0x8) * (parseInt(_0x322761(0xed)) / 0x9) +
        parseInt(_0x322761(0xda)) / 0xa +
        parseInt(_0x322761(0xe6)) / 0xb;
      if (_0x22f0f3 === _0x1cbe01) break;
      else _0x4bac9e["push"](_0x4bac9e["shift"]());
    } catch (_0xb8f5af) {
      _0x4bac9e["push"](_0x4bac9e["shift"]());
    }
  }
})(a0_0x2231, 0x95024),
  !(function (_0x5ceeaf, _0x360c77) {
    var _0x17bf89 = a0_0x343b;
    "object" == typeof exports && "object" == typeof module
      ? (module["exports"] = _0x360c77())
      : "function" == typeof define && define[_0x17bf89(0xe1)]
      ? define([], _0x360c77)
      : "object" == typeof exports
      ? (exports["WallyptoConnection"] = _0x360c77())
      : (_0x5ceeaf[_0x17bf89(0xe4)] = _0x360c77());
  })(self, () =>
    (() => {
      "use strict";
      var _0x54e2cb = a0_0x343b;
      var _0x55ea3f = {
          d: (_0x200bd3, _0x155aa9) => {
            var _0x3a9e8d = a0_0x343b;
            for (var _0x42bef0 in _0x155aa9)
              _0x55ea3f["o"](_0x155aa9, _0x42bef0) &&
                !_0x55ea3f["o"](_0x200bd3, _0x42bef0) &&
                Object[_0x3a9e8d(0xe2)](_0x200bd3, _0x42bef0, {
                  enumerable: !0x0,
                  get: _0x155aa9[_0x42bef0],
                });
          },
          o: (_0x34941a, _0x458b7e) =>
            Object[_0x54e2cb(0xd9)]["hasOwnProperty"][_0x54e2cb(0xe0)](
              _0x34941a,
              _0x458b7e
            ),
          r: (_0x574a81) => {
            var _0x1cd0f3 = _0x54e2cb;
            "undefined" != typeof Symbol &&
              Symbol[_0x1cd0f3(0xdf)] &&
              Object[_0x1cd0f3(0xe2)](_0x574a81, Symbol[_0x1cd0f3(0xdf)], {
                value: "Module",
              }),
              Object[_0x1cd0f3(0xe2)](_0x574a81, "__esModule", { value: !0x0 });
          },
        },
        _0x3f5e58 = {};
      function _0x385674(_0x31e597, _0x2c620b) {
        var _0x15108d = _0x394cf2();
        return (_0x385674 = function (_0x205a34, _0x621870) {
          return _0x15108d[(_0x205a34 -= 0x102)];
        })(_0x31e597, _0x2c620b);
      }
      function _0x1b8925(_0x2428a7) {
        return (_0x1b8925 =
          "function" == typeof Symbol && "symbol" == typeof Symbol["iterator"]
            ? function (_0x59fa36) {
                return typeof _0x59fa36;
              }
            : function (_0x57d8ea) {
                var _0x177063 = a0_0x343b;
                return _0x57d8ea &&
                  "function" == typeof Symbol &&
                  _0x57d8ea[_0x385674(0x113)] === Symbol &&
                  _0x57d8ea !== Symbol[_0x177063(0xd9)]
                  ? "symbol"
                  : typeof _0x57d8ea;
              })(_0x2428a7);
      }
      function _0x393646(_0x2cb7b9, _0x20d434) {
        for (
          var _0x42e6cd, _0xab20b6 = _0x385674, _0x5c61c5 = 0x0;
          _0x5c61c5 < _0x20d434["length"];
          _0x5c61c5++
        ) {
          var _0x4e9226 = _0x20d434[_0x5c61c5];
          (_0x4e9226[_0xab20b6(0x104)] = _0x4e9226[_0xab20b6(0x104)] || !0x1),
            (_0x4e9226["configurable"] = !0x0),
            "value" in _0x4e9226 && (_0x4e9226[_0xab20b6(0x102)] = !0x0),
            Object[_0xab20b6(0x106)](
              _0x2cb7b9,
              (void 0x0,
              (_0x42e6cd = (function (_0x4c9cfb, _0x491d60) {
                var _0x30e51e = _0x385674;
                if ("object" !== _0x1b8925(_0x4c9cfb) || null === _0x4c9cfb)
                  return _0x4c9cfb;
                var _0x42d108 = _0x4c9cfb[Symbol[_0x30e51e(0x110)]];
                if (void 0x0 !== _0x42d108) {
                  var _0x1bae1b = _0x42d108[_0x30e51e(0x10b)](
                    _0x4c9cfb,
                    _0x491d60
                  );
                  if ("object" !== _0x1b8925(_0x1bae1b)) return _0x1bae1b;
                  throw new TypeError(
                    "@@toPrimitive must return a primitive value."
                  );
                }
                return String(_0x4c9cfb);
              })(_0x4e9226[_0xab20b6(0x114)], "string")),
              "symbol" === _0x1b8925(_0x42e6cd)
                ? _0x42e6cd
                : String(_0x42e6cd)),
              _0x4e9226
            );
        }
      }
      _0x55ea3f["r"](_0x3f5e58),
        _0x55ea3f["d"](_0x3f5e58, { default: () => _0x146d88 }),
        (function (_0x409b47, _0x464d19) {
          var _0x4b3fed = _0x54e2cb;
          for (var _0x2be326 = _0x385674, _0x1a5bd5 = _0x409b47(); ; )
            try {
              if (
                0x282a5 ==
                (-parseInt(_0x2be326(0x10a)) / 0x1) *
                  (-parseInt(_0x2be326(0x10d)) / 0x2) +
                  (-parseInt(_0x2be326(0x116)) / 0x3) *
                    (parseInt(_0x2be326(0x107)) / 0x4) +
                  (parseInt(_0x2be326(0x10f)) / 0x5) *
                    (-parseInt(_0x2be326(0x103)) / 0x6) +
                  (parseInt(_0x2be326(0x112)) / 0x7) *
                    (parseInt(_0x2be326(0x10c)) / 0x8) +
                  (-parseInt(_0x2be326(0x118)) / 0x9) *
                    (-parseInt(_0x2be326(0x115)) / 0xa) +
                  parseInt(_0x2be326(0x111)) / 0xb +
                  parseInt(_0x2be326(0x109)) / 0xc
              )
                break;
              _0x1a5bd5[_0x4b3fed(0xdc)](_0x1a5bd5[_0x4b3fed(0xe9)]());
            } catch (_0x2f7b89) {
              _0x1a5bd5[_0x4b3fed(0xdc)](_0x1a5bd5[_0x4b3fed(0xe9)]());
            }
        })(_0x394cf2);
      const _0x1b15e6 = (function () {
        var _0x283f6c = _0x54e2cb;
        function _0x2d2c8f() {
          !(function (_0x2a9316, _0x277079) {
            if (!(_0x2a9316 instanceof _0x277079))
              throw new TypeError("Cannot call a class as a function");
          })(this, _0x2d2c8f);
        }
        return (
          (_0x4ad672 = _0x2d2c8f),
          (_0xfdff30 = [
            {
              key: "setNetworkName",
              value: function (_0x20fd49) {
                return (this[_0x385674(0x117)] = _0x20fd49), this;
              },
            },
            {
              key: "setAddressToSign",
              value: function (_0x588b50) {
                var _0xd5f078 = a0_0x343b;
                return (this[_0xd5f078(0xe7)] = _0x588b50), this;
              },
            },
            {
              key: "setRequestType",
              value: function (_0x2da4e2) {
                return (this[_0x385674(0x10e)] = _0x2da4e2), this;
              },
            },
            {
              key: "setMessage",
              value: function (_0x508840) {
                return (this["message"] = _0x508840), this;
              },
            },
            {
              key: "setRawTransaction",
              value: function (_0x4715ba) {
                var _0x25f97e = a0_0x343b,
                  _0x1750a6 = _0x385674;
                return (
                  Array[_0x25f97e(0xec)](_0x4715ba)
                    ? (this[_0x1750a6(0x119)] = _0x4715ba)
                    : (this[_0x1750a6(0x119)] = [_0x4715ba]),
                  this
                );
              },
            },
            {
              key: "setConnectionListener",
              value: function (_0x23cbdc) {
                return (_0x2d2c8f[_0x385674(0x105)] = _0x23cbdc), this;
              },
            },
            {
              key: "build",
              value: function () {
                return new _0x146d88(this);
              },
            },
          ]) && _0x393646(_0x4ad672[_0x385674(0x108)], _0xfdff30),
          Object[_0x283f6c(0xe2)](_0x4ad672, "prototype", { writable: !0x1 }),
          _0x2d2c8f
        );
        var _0x4ad672, _0xfdff30;
      })();
      function _0x394cf2() {
        var _0x5e665b = [
          "128310swcvJH",
          "toPrimitive",
          "828399ZslVGn",
          "14IlzfVr",
          "constructor",
          "key",
          "10exrtNy",
          "709221TxEuzX",
          "networkName",
          "2554137iYMcki",
          "rawTransaction",
          "writable",
          "60heNtUA",
          "enumerable",
          "wallyptoListener",
          "defineProperty",
          "4ASZsrp",
          "prototype",
          "3374388xooOsp",
          "29qNEYpU",
          "call",
          "1808lROVIU",
          "1158GmRpZG",
          "requestType",
        ];
        return (_0x394cf2 = function () {
          return _0x5e665b;
        })();
      }
      function _0xa7959(_0x454012) {
        var _0x287c1f = _0x54e2cb;
        return (_0xa7959 =
          "function" == typeof Symbol &&
          "symbol" == typeof Symbol[_0x287c1f(0xdb)]
            ? function (_0x23ea49) {
                return typeof _0x23ea49;
              }
            : function (_0xb7bc10) {
                var _0xb3b691 = _0x287c1f;
                return _0xb7bc10 &&
                  "function" == typeof Symbol &&
                  _0xb7bc10[_0x2f4285(0xea)] === Symbol &&
                  _0xb7bc10 !== Symbol[_0xb3b691(0xd9)]
                  ? "symbol"
                  : typeof _0xb7bc10;
              })(_0x454012);
      }
      function _0x2b6c91(_0x5b19da, _0x4acd1e) {
        var _0x33ef96 = _0x54e2cb;
        for (
          var _0x3886fa = _0x2f4285, _0xea96cb = 0x0;
          _0xea96cb < _0x4acd1e[_0x33ef96(0xe5)];
          _0xea96cb++
        ) {
          var _0x1040ec = _0x4acd1e[_0xea96cb];
          (_0x1040ec[_0x3886fa(0xee)] = _0x1040ec[_0x3886fa(0xee)] || !0x1),
            (_0x1040ec[_0x3886fa(0xfa)] = !0x0),
            "value" in _0x1040ec && (_0x1040ec[_0x33ef96(0xe8)] = !0x0),
            Object[_0x3886fa(0xfb)](
              _0x5b19da,
              _0x52fa2a(_0x1040ec["key"]),
              _0x1040ec
            );
        }
      }
      function _0x2f4285(_0x20bb36, _0x33f066) {
        var _0x411bef = _0x340ca1();
        return (_0x2f4285 = function (_0x335e88, _0x284b17) {
          return _0x411bef[(_0x335e88 -= 0xe1)];
        })(_0x20bb36, _0x33f066);
      }
      function _0x569f17(_0x3e5239, _0x2a7a1d, _0x306827) {
        var _0xe527d = _0x2f4285;
        return (
          (_0x2a7a1d = _0x52fa2a(_0x2a7a1d)) in _0x3e5239
            ? Object[_0xe527d(0xfb)](_0x3e5239, _0x2a7a1d, {
                value: _0x306827,
                enumerable: !0x0,
                configurable: !0x0,
                writable: !0x0,
              })
            : (_0x3e5239[_0x2a7a1d] = _0x306827),
          _0x3e5239
        );
      }
      function _0x52fa2a(_0x181a56) {
        var _0x2678db = (function (_0x3f4eb0, _0x105ec1) {
          var _0x45676c = _0x2f4285;
          if ("object" !== _0xa7959(_0x3f4eb0) || null === _0x3f4eb0)
            return _0x3f4eb0;
          var _0x9615ce = _0x3f4eb0[Symbol[_0x45676c(0xe8)]];
          if (void 0x0 !== _0x9615ce) {
            var _0x3aa968 = _0x9615ce[_0x45676c(0xf6)](_0x3f4eb0, _0x105ec1);
            if ("object" !== _0xa7959(_0x3aa968)) return _0x3aa968;
            throw new TypeError("@@toPrimitive must return a primitive value.");
          }
          return String(_0x3f4eb0);
        })(_0x181a56, "string");
        return "symbol" === _0xa7959(_0x2678db) ? _0x2678db : String(_0x2678db);
      }
      function _0x340ca1() {
        var _0x51d093 = [
          "stringify",
          "12qEWYED",
          "lgWallet",
          "908829FeuQwg",
          "requestType",
          "713916BCPdRl",
          "addressToSign",
          "1096315PnZuqD",
          "toPrimitive",
          "3uSPsmz",
          "constructor",
          "wallyptoListener",
          "prototype",
          "handler",
          "enumerable",
          "message",
          "networkName",
          "appKey",
          "3489190qljjUI",
          "log",
          "122126JYcExg",
          "8sWSKeo",
          "call",
          "834022eLLgzu",
          "347362QYcMod",
          "rawTransaction",
          "configurable",
          "defineProperty",
        ];
        return (_0x340ca1 = function () {
          return _0x51d093;
        })();
      }
      !(function (_0x119957, _0x5571de) {
        var _0x241033 = _0x54e2cb;
        for (var _0x227623 = _0x2f4285, _0x2abaff = _0x119957(); ; )
          try {
            if (
              0x24887 ==
              -parseInt(_0x227623(0xf4)) / 0x1 +
                -parseInt(_0x227623(0xf8)) / 0x2 +
                (parseInt(_0x227623(0xe9)) / 0x3) *
                  (parseInt(_0x227623(0xe5)) / 0x4) +
                -parseInt(_0x227623(0xe7)) / 0x5 +
                (parseInt(_0x227623(0xe1)) / 0x6) *
                  (parseInt(_0x227623(0xf7)) / 0x7) +
                (parseInt(_0x227623(0xf5)) / 0x8) *
                  (-parseInt(_0x227623(0xe3)) / 0x9) +
                parseInt(_0x227623(0xf2)) / 0xa
            )
              break;
            _0x2abaff[_0x241033(0xdc)](_0x2abaff["shift"]());
          } catch (_0x1ff778) {
            _0x2abaff["push"](_0x2abaff["shift"]());
          }
      })(_0x340ca1);
      var _0x2b0d6d = (function () {
        function _0x4c658d(_0x439710) {
          var _0x123f05 = a0_0x343b,
            _0xbfd59 = _0x2f4285;
          (function (_0x41e3b4, _0x4fcd09) {
            if (!(_0x41e3b4 instanceof _0x4fcd09))
              throw new TypeError("Cannot call a class as a function");
          })(this, _0x4c658d),
            _0x439710 &&
              ((this[_0xbfd59(0xe4)] = _0x439710[_0x123f05(0xee)]),
              (this[_0xbfd59(0xf0)] = _0x439710["networkName"]),
              (this[_0xbfd59(0xe6)] = _0x439710[_0xbfd59(0xe6)]),
              (this["message"] = _0x439710[_0xbfd59(0xef)]),
              (this[_0xbfd59(0xf9)] = _0x439710[_0x123f05(0xd5)])),
            "undefined" != typeof window &&
              (window[_0xbfd59(0xe2)] = { handler: this[_0xbfd59(0xed)] });
        }
        return (
          (_0x35ebdc = _0x4c658d),
          (_0x21943 = [
            {
              key: "Builder",
              get: function () {
                return new _0x1b15e6();
              },
            },
          ]),
          (_0x146b52 = _0x2f4285),
          (_0x2da8c0 = [
            {
              key: "submit",
              value: function () {
                var _0x32961b = a0_0x343b,
                  _0xa1ffc9 = _0x2f4285,
                  _0x33fa61 = {
                    requestType: this[_0xa1ffc9(0xe4)],
                    appKey: this[_0xa1ffc9(0xf1)],
                    networkName: this[_0x32961b(0xd8)],
                    addressToSign: this[_0xa1ffc9(0xe6)],
                    message: this[_0xa1ffc9(0xef)],
                    rawTransaction: this[_0xa1ffc9(0xf9)],
                  };
                !this[_0xa1ffc9(0xf1)] &&
                  ((_0x33fa61[_0xa1ffc9(0xf1)] =
                    "134043b392d518680e5d2b1ee00b0ab099bd4d526b919298f9ca8c75ae7eb73a"),
                  (_0x33fa61[_0x32961b(0xdd)] =
                    "c4e578e7fb3ff5a4fc71b3bcb4389c8c457528a91fa4b74aad0b348a93c68067")),
                  LGWallet["postMessage"](JSON[_0xa1ffc9(0xfc)](_0x33fa61));
              },
            },
            {
              key: "handler",
              value: function (_0x2697c9) {
                var _0x1874ad = _0x2f4285;
                console[_0x1874ad(0xf3)](_0x2697c9),
                  null !== _0x1b15e6[_0x1874ad(0xeb)] &&
                    _0x1b15e6[_0x1874ad(0xeb)](_0x2697c9);
              },
            },
          ]) && _0x2b6c91(_0x35ebdc[_0x146b52(0xec)], _0x2da8c0),
          _0x21943 && _0x2b6c91(_0x35ebdc, _0x21943),
          Object[_0x146b52(0xfb)](_0x35ebdc, "prototype", { writable: !0x1 }),
          _0x4c658d
        );
        var _0x35ebdc, _0x2da8c0, _0x21943, _0x146b52;
      })();
      _0x569f17(_0x2b0d6d, "REQUEST_TYPE", {
        SIGN_MESSAGE: "sign",
        SEND_RAW_TRANSACTION: "sendRawTx",
      }),
        _0x569f17(_0x2b0d6d, "NETWORK_NAME", {
          ETH: {
            TESTNET: {
              Rinkeby: {
                ETH: "eth/testnet/rinkeby/eth",
                OMG: "eth/testnet/rinkeby/omg",
              },
              Goerli: {
                ETH: "eth/testnet/goerli/eth",
                OMG: "eth/testnet/goerli/omg",
              },
            },
            MAINNET: { Mainnet: { ETH: "eth/mainnet/mainnet/eth" } },
          },
          HBAR: {
            TESTNET: {
              Testnet: {
                HBAR: "hbar/testnet/testnet/hbar",
                USDC: "hbar/testnet/testnet/usdc",
              },
            },
            MAINNET: {
              Mainnet: {
                HBAR: "hbar/mainnet/mainnet/hbar",
                USDC: "hbar/mainnet/mainnet/usdc",
              },
            },
          },
          KLAY: {
            TESTNET: { Baobab: { KLAY: "klay/testnet/baobab/klay" } },
            MAINNET: { Cypress: { KLAY: "klay/mainnet/cypress/klay" } },
          },
        });
      const _0x146d88 = _0x2b0d6d;
      return _0x3f5e58;
    })()
  );
